<template>
<Page id="tournaments" title="Turneringar">
      <Loader v-if="tournamentsloading" loadingtext="Hämtar turneringar..."></Loader>
      <div v-else>            
            <div class="mb-4" v-if="ownertournaments.length > 0">
                  <h4 class="text-center mb-4">Dina turneringar</h4>
                  <div >
                        <TurnamentListItem v-on:click.native="HandleTournamentClick(tournament)" v-for="tournament in ownertournaments" :key="tournament.id" :tournament="tournament" :useraccess="userTournamentAccess(tournament.id)"></TurnamentListItem>
                  </div>                  
            </div>

            <div class="mb-4">
                  <h4 class="text-center mb-4">Aktiva turneringar</h4>
                  <div v-if="activetournaments.length > 0">
                        <TurnamentListItem v-on:click.native="HandleTournamentClick(tournament)" v-for="tournament in activetournaments" :key="tournament.id" :tournament="tournament" :useraccess="userTournamentAccess(tournament.id)"></TurnamentListItem>
                  </div>
                  <Alert v-else variant="info" >Just nu finns inga aktiva turneringar</Alert>
            </div>

            <div class="mt-4">
                  <h4 class="text-center mb-4">Kommande turneringar</h4>
                  <div v-if="commingtournaments.length > 0">
                        <TurnamentListItem v-for="tournament in commingtournaments" :key="tournament.id" :tournament="tournament" :useraccess="userTournamentAccess(tournament.id)"></TurnamentListItem>
                  </div>
                  <Alert centered v-else variant="info" >Just nu finns inga kommande turneringar</Alert>
            </div>

            <div class="mt-4">
                  <h4 class="text-center mb-4">Avslutade turneringar</h4>
                  <div v-if="closedtournaments.length > 0">
                        <TurnamentListItem v-on:click.native="HandleTournamentClick(tournament)" v-for="tournament in closedtournaments" :key="tournament.id" :tournament="tournament" :useraccess="userTournamentAccess(tournament.id)"></TurnamentListItem>
                  </div>
                  <Alert v-else variant="info" >Just nu finns inga avslutade turneringar</Alert>
            </div>
      </div>

      <b-modal v-if="showJoin" :hide-footer="joinloding" :visible="true" @ok="JoinTournament()" @cancel="JoinClose()" @hide="JoinClose()" ok-title="Delta" cancel-title="Avbryt" footer-class="justify-content-center" :title="'Delta i ' + currentJoinTournament.name ">
            <TournamentJoin :tournament="currentJoinTournament"></TournamentJoin>
      </b-modal>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const TurnamentListItem = () => import("@/components/tournaments/TurnamentListItem");
const TournamentJoin = () => import("@/components/tournaments/JoinTournamentConfirm");

import tournamentsenum from "@/helpers/enums/tournaments";

export default {
      name: "Tournaments",
      components: {
            Page,
            Alert,
            Loader,
            TurnamentListItem,
            TournamentJoin
      },
      data() {
            return {
                  showJoin: false,
                  currentJoinTournament: false
            };
      },
      metaInfo: {
            title: "Turneringar",
            meta: [{
                  name: "description",
                  content: "Valbara turneringar",
            }, ],
      },
      computed: {
            tournamentsloading() {
                  return this.$store.getters["tournamentsg/tournamentsloading"] || this.$store.getters["tournamentsg/usertournamentsloading"];
            },
            currentUser() {
                  return this.$store.state.auth.user;
            },
            ownertournaments() {
                  var items = this.$store.getters["tournamentsg/tournaments"];
                  if (!items) items = [];
                  return items.filter((item) => {
                        return item.ownerid == this.currentUser.id
                  });
            },
            activetournaments() {
                  var items = this.$store.getters["tournamentsg/tournaments"];
                  if (!items) items = [];
                  return items.filter((item) => {
                        return item.status == 2 && item.active == 1;
                  });
            },
            commingtournaments() {
                  var items = this.$store.getters["tournamentsg/tournaments"];
                  if (!items) items = [];
                  return items.filter((item) => {
                        return item.status == 2 && item.active == 0;
                  });
            },
            closedtournaments() {
                  var items = this.$store.getters["tournamentsg/tournaments"];
                  if (!items) items = [];
                  return items.filter((item) => {
                        return item.status == 2 && item.active == 2;
                  });
            },
            usertournaments() {
                  var items = this.$store.getters["tournamentsg/usertournaments"];
                  if (!items)
                        return false;
                  return items;
            },
            joinloding() {
                  return this.$store.getters["tournamentsp/joinloading"];
            }
      },
      methods: {        
            userTournamentAccess(id) {
                  if (this.usertournaments && this.usertournaments[id]) {
                        return this.usertournaments[id].Access;
                  }
                  return false;
            },
            HandleTournamentClick(tournament) {
                  var uacess = this.userTournamentAccess(tournament.id);
                  // om man har access så går vi in
                  if (tournamentsenum.userHasAccess(uacess)) {
                        // välj
                        this.$store.dispatch("tournamentsp/selectTournament", tournament.id).then(() => {
                              window.location.href = "/";
                        });
                  } else if (tournamentsenum.tournamentOpen(tournament.active)) {
                        if (uacess) // ansökt
                              alert("Du har ansökt");
                        else // om öppen så försök gå med
                        {
                              this.currentJoinTournament = tournament;
                              this.showJoin = true;
                        }
                  }
            },
            JoinTournament() {

                  // välj
                  this.$store.dispatch("tournamentsp/joinTournament", this.currentJoinTournament.id).then(() => {
                        //this.JoinClose();
                        window.location.href = "/";
                  });
            },
            JoinClose() {
                  this.showJoin = false;
                  this.currentJoinTournament = false;
            }
      },
      mounted() {
            this.$store.dispatch("tournamentsg/getTournaments");
            this.$store.dispatch("tournamentsg/getUserTournaments");
      },
};
</script>
