<template>
<Page id="results" title="Resultat & spelschema">
      <Loader v-if="gamesloading" loadingtext="Hämtar resultat..."></Loader>
      <Alert v-else-if="!hasGames" variant="info" >Det finns just nu inga resultat att visa</Alert>
      <div v-else>
            <b-row no-gutters class="my-3 align-items-center">
                  <b-col cols="12" md="3" class="font-weight-bold">
                        Sortera resultat efter:
                  </b-col>
                  <b-col cols="12" md="9">
                        <b-select v-model="display">
                              <b-select-option value="1">Matchdatum</b-select-option>
                              <b-select-option value="2">Grupp</b-select-option>
                        </b-select>
                  </b-col>
            </b-row>

            <DayGames v-if="display==1" :games="gamesd"></DayGames>
            <GroupGames v-else-if="display==2" :games="gamesg"></GroupGames>
      </div>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const DayGames = () => import("@/components/games/DayGames");
const GroupGames = () => import("@/components/games/GroupGames");

export default {
      name: "Results",
      components: {
            Page,
            Alert,
            Loader,
            DayGames,
            GroupGames
      },
      data() {
            return {
                  display: 1, // 1 days, 2 group,
                  hasGames: false,
                  gamesd: [],
                  gamesg: []
            };
      },
      metaInfo: {
            title: "Resultat",
            meta: [{
                  name: "description",
                  content: "Se alla resultat här",
            }, ],
      },
      computed: {
            gamesloading() {
                  return this.$store.getters["games/gamesloading"];
            },           
      },
      methods: {
            _getGamesByKey(key, sortkey) {
                  if (!sortkey)
                        sortkey = key;
                  var games = this.$store.getters["games/games"];
                  if (!games)
                        games = [];

                  if(games.length > 0)
                        this.hasGames = true;
                  
                  var sorted = this.$functions.sort(games, sortkey);

                  games = this.$functions.groupGamesByKey(sorted, key);

                  return games;
            }
      },
      mounted() {
            this.$store.dispatch("games/getGames").then(() => {
                  this.gamesd = this._getGamesByKey("gameday", "gamedate");
                  this.gamesg = this._getGamesByKey("groupid");
            });
      },
};
</script>
