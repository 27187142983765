export default {
      status: {
            0: "Skapad",
            1: "Ej godkänd",
            2: "Godkänd",
            3: "Spärrad"
      },
      active:
      {
            0: { value: 0, text: "Ej påbörjad", bg: "warning", txt: "white" },
            1: { value: 1, text: "Pågående", bg: "success", txt: "white" },
            2: { value: 2, text: "Avslutad", bg: "danger", txt: "white" },
      },
      sport: {
            1: "Fotboll",
            2: "Ishockey",
            3: "Handboll"
      },
      access: {
            0: { value: 0, text: "Ansökt", bg: "info", txt: "white" }, // ansökt ej godkänd
            10: { value: 10, text: "Godkänd, Ej betalt", bg: "danger", txt: "white" }, // godkänd ej betalande
            20: { value: 20, text: "Betalande", bg: "warning", txt: "white" }, // godkänd betalande /kan ej se mål endast 1x2
            21: { value: 21, text: "Betalande VIP", bg: "success", txt: "white" }, // godkänd betalande kan se allt              
            30: { value: 30, text: "Admin", bg: "success", txt: "white" } // ägare    
      },
      tournamentOpen(tactive)
      {
            return tactive == 1;
      },
      userHasAccess(access)
      {
            return access && access.access >= 10;
      },
      tournamentSport(sport){
            if(this.sport[sport])
                  return this.sport[sport];
            return false;
      },
      getTournamentActiveItem(tactive)
      {
            return this.active[tactive];
      },
      getUserAccess(accesslevel)
      {
            if(this.access[accesslevel])
                  return this.access[accesslevel];
            return false;
      },
      getUserAccessItem(uaccess, tactive)
      {
            if (uaccess)
            {
                  return this.access[uaccess.access];
            } else
            {
                  switch (tactive)
                  {
                        case "0": // ej öppnad
                        case "1": // påbörjad
                              return { value:-10, text: "Klicka för att delta", bg: "light", txt: "dark"}
                        case "2": // avslutad
                              return { value: -1, text: "Du deltog inte", bg: "light", txt: "dark" };
                  }
            }

      }

};